<template>
  <div>
    <ImgGroupInput v-model="imgFiles" />
    <v-btn color="primary" @click="submit" :loading="loading">Submit</v-btn>
    <v-item-group>
      <v-container>
        <v-row>
          <v-col
            v-for="img in resList"
            :key="img"
            cols="12"
            md="4"
            sm="6"
            xs="12"
          >
            <v-item>
              <v-card class="d-inline-block mx-auto">
                <v-container>
                  <v-row justify="space-between">
                    <v-col cols="12">
                      <v-img contain :src="img"></v-img>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0" justify="end">
                    <v-col cols="auto" class="pa-0">
                      <v-btn icon :href="img" target="_blank">
                        <v-icon small>mdi-open-in-new</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
  </div>
</template>

<script>
import ImgGroupInput from "@/components/ImgGroupInput";
import objectToFormData from "../../../utils/objectToFormData";
import postAxios from "../../../services/axios-post";
export default {
  components: {
    ImgGroupInput,
  },
  data() {
    return {
      imgFiles: [],
      resList: [],
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const resList = await Promise.all(
          this.imgFiles.map(img => {
            return postAxios(
              `${process.env.VUE_APP_SERVER_URL}/file/carOcrBeta`,
              objectToFormData({ file: img.file })
            ).catch(err => {
              console.error(err);
            });
          })
        );
        console.log(resList);
        this.resList = resList.map(res => res.data.data);
      } finally {
        this.loading = false;
      }
    },
    // download(item) {

    // }
  },
};
</script>

<style></style>
